<template>
  <BCard>
    <div class="flex items-center justify-between mb-2">
      <div class="flex gap-2">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black text-2xl font-semibold">
          Detail Order Packing
        </div>
      </div>
      <b-badge
        v-if="isOrderDibatalkan"
        variant="light-secondary"
        class="!rounded-full px-2 py-1"
      >Dibatalkan</b-badge>
    </div>

    <div
      class="flex justify-end space-x-3"
    >
      <BButton
        :variant="isOrderDibatalkan ? 'outline-secondary' : 'outline-primary'"
        :disabled="urlDownloadLabel === '' || isOrderDibatalkan"
        @click="downloadLinkLable(urlDownloadLabel)"
      >
        Download Label
      </BButton>
    </div>

    <div class="flex items-start bg-[#DFF3FF] px-[14px] py-[10px] space-x-3 rounded-lg my-6">
      <img
        src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
        alt="info-circle"
      >
      <span class="text-[#333]">Pastikan pesanan sejalan dengan label pengiriman. Checklist orderan yang sesuai dengan label pengiriman. Jika ada orderan yang tak sesuai atau resi berlebih, langsung hubungi partner untuk pengajuan susulan. Kita selalu siap membantu!</span>
    </div>
    <BTableSimple bordered>
      <BThead>
        <BTr>
          <BTh class="!capitalize">
            Jenis Order
          </BTh>
          <BTh class="!capitalize">
            Produk
          </BTh>
          <BTh class="!capitalize">
            Variasi
          </BTh>
          <BTh class="!capitalize">
            Jumlah Item
          </BTh>
          <BTh class="!capitalize">
            Jumlah Order
          </BTh>
          <BTh class="!capitalize">
            Bahan Packing
          </BTh>
          <BTh
            class="!capitalize"
            style="width: 190px !important;"
          >
            Sesuai Resi
          </BTh>
        </BTr>
      </BThead>
      <BTbody>
        <BTr
          v-for="(item, index) in items"
          :key="index"
        >
          <!-- Order Number Cell with Rowspan -->
          <BTd
            v-if="item.orderRowspan > 0"
            :rowspan="item.orderRowspan"
            class="align-top"
          >
            {{ item.order_number }}
          </BTd>
          <!-- Product Name -->
          <BTd
            v-if="item.productRowspan > 0"
            :rowspan="item.productRowspan"
            class="align-top"
          >
            {{ item.product_name }}
          </BTd>
          <!-- Product Variant Name -->
          <BTd class="align-top">
            {{ item.product_variant_name }}
          </BTd>
          <!-- Total Item -->
          <BTd
            v-if="item.productRowspan > 0"
            :rowspan="item.productRowspan"
            class="align-top"
          >
            {{ item.total_item }}
          </BTd>
          <!-- Total Order Cell with Rowspan -->
          <BTd
            v-if="item.orderRowspan > 0"
            :rowspan="item.orderRowspan"
            class="align-top"
          >
            {{ item.total_order }}
          </BTd>
          <!-- Packing Material Order -->
          <BTd
            v-if="item.orderRowspan > 0"
            :rowspan="item.orderRowspan"
            class="align-top"
          >
            <list-packing-materials :items="item.packings" />
          </BTd>
          <!-- Total Order Approved Cell with Rowspan -->
          <BTd
            v-if="item.orderRowspan > 0"
            :rowspan="item.orderRowspan"
            style="width: 190px !important;"
            class="align-top"
          >
            <b-form-input
              v-if="!confirmPacking"
              v-model="item.total_order_approved"
              :formatter="formatNumber"
              :disabled="isOrderDibatalkan"
              class="w-50"
              number
              @input="updateTotalOrderApproved(item.order_number, item.total_order_approved)"
            />
            <span
              v-else
            >{{ item.total_order_approved }}</span>
          </BTd>
        </BTr>
        <BTr
          v-if="loading"
        >
          <BTd
            :colspan="fields.length"
            class="text-center"
          >
            <b-spinner
              class="align-middle mr-6"
              variant="primary"
            />
            <span>Loading...</span>
          </BTd>
        </BTr>
      </BTbody>
    </BTableSimple>
    <div class="text-black space-y-2 mt-6">
      <b-row align-h="end">
        <b-col cols="2">
          Total Order
        </b-col>
        <b-col cols="0">
          :
        </b-col>
        <b-col cols="2">
          <span>
            {{ disabledConfirm() ? '-' : totalApprovedSum }}
          </span>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="2">
          Total Biaya
        </b-col>
        <b-col cols="0">
          :
        </b-col>
        <b-col cols="2">
          <span>
            {{ disabledConfirm() ? '-' : totalFulfillmentFee | rupiah }}
          </span>
        </b-col>
      </b-row>
      <div v-if="!confirmPacking">
        <b-button
          :variant="disabledConfirm() ? 'secondary' : 'primary'"
          class="mt-6 float-right"
          :disabled="disabledConfirm()"
          @click="confirm()"
        >
          Konfirmasi
        </b-button>
      </div>
    </div>
  </BCard>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import filters from '@/libs/filters'
import ListPackingMaterials from './ListPackingMaterials.vue'

export default {
  components: {
    ListPackingMaterials,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      loading: false,
      confirmPacking: true,
      items: [],
      orderStatus: '',
      urlDownloadLabel: '',
      fields: [
        { key: 'order_number', label: 'Jenis Order', thClass: '!capitalize' },
        { key: 'product_name', label: 'Produk', thClass: '!capitalize' },
        { key: 'product_variant_name', label: 'Variasi', thClass: '!capitalize' },
        { key: 'total_item', label: 'Jumlah Item', thClass: '!capitalize' },
        { key: 'total_order', label: 'Jumlah Order', thClass: '!capitalize' },
        { key: 'total_order_approved', label: 'Sesuai Resi', thClass: '!capitalize' },
      ],
    }
  },
  computed: {
    isOrderDibatalkan() {
      return this.orderStatus === 'Dibatalkan'
    },
    totalApprovedSum() {
      const uniqueOrderNumbers = new Set()
      let sum = 0

      this.items.forEach(item => {
        if (!uniqueOrderNumbers.has(item.order_number)) {
          uniqueOrderNumbers.add(item.order_number)
          // eslint-disable-next-line radix
          sum += parseInt(item.total_order_approved)
        }
      })

      return sum
    },
    totalFulfillmentFee() {
      const uniqueOrders = new Set()
      this.items.forEach(order => {
        uniqueOrders.add(order.order_number)
      })
      let total = 0

      uniqueOrders.forEach(orderNumber => {
        const orderFulfillmentFee = this.items.find(order => order.order_number === orderNumber).fulfillment_fee_per_order
        const totalOrderApproved = this.items.find(order => order.order_number === orderNumber).total_order_approved
        total += orderFulfillmentFee * totalOrderApproved
      })
      return total
    },
    payload() {
      const orderSet = new Set()
      const payloadArray = []

      this.items.forEach(order => {
        if (!orderSet.has(order.outbound_detail_id)) {
          orderSet.add(order.outbound_detail_id)
          payloadArray.push({
            outbound_detail_id: order.outbound_detail_id,
            total_valid_order: order.total_order_approved,
          })
        }
      })

      return {
        grand_fulfillment_fee: this.totalFulfillmentFee,
        grand_total_order: this.totalApprovedSum,
        payload: payloadArray,
      }
    },
  },
  created() {
    this.getDetailOrder()
  },
  methods: {
    async getDetailOrder() {
      this.loading = true
      await newAxiosIns.get(`/kompack/api/v1/mitra/outbounds/${this.$route.params.id}/detail`)
        .then(res => {
          this.loading = false

          const { data } = res.data

          this.orderStatus = data.status

          this.confirmPacking = data.is_confirmed
          this.urlDownloadLabel = data.label_url

          const result = []
          const orderCount = {}
          const productCount = {}

          data.order_summary.forEach((order, index) => {
            order.products.forEach((product, index2) => {
              if (product.is_bundling && product.product_bundles.length > 0) {
                product.product_bundles.forEach(bundle => {
                  const orderNumber = index + 1
                  const productId = `${index2}_${product.product_id}`

                  if (!orderCount[orderNumber]) {
                    orderCount[orderNumber] = 0
                  }
                  orderCount[orderNumber] += 1

                  if (!productCount[orderNumber]) {
                    productCount[orderNumber] = {}
                  }
                  if (!productCount[orderNumber][productId]) {
                    productCount[orderNumber][productId] = 0
                  }
                  productCount[orderNumber][productId] += 1

                  result.push({
                    order_number: orderNumber,
                    outbound_detail_id: order.outbound_detail_id,
                    total_order: order.total_order,
                    total_order_approved: order.total_order_approved,
                    fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                    product_id: productId,
                    bundle_id: bundle.product_id,
                    product_variant_id: bundle.product_variant_id,
                    product_name: product.product_name,
                    product_variant_name: bundle.product_variant_name || bundle.product_name,
                    total_item: product.total_item,
                    is_bundling: product.is_bundling,
                    product_fulfillment: product.product_fulfillment,
                    packings: order.packings,
                  })
                })
              } else {
                const orderNumber = index + 1
                const productId = `${index2}_${product.product_id}`

                if (!orderCount[orderNumber]) {
                  orderCount[orderNumber] = 0
                }
                orderCount[orderNumber] += 1

                if (!productCount[orderNumber]) {
                  productCount[orderNumber] = {}
                }
                if (!productCount[orderNumber][productId]) {
                  productCount[orderNumber][productId] = 0
                }
                productCount[orderNumber][productId] += 1

                result.push({
                  order_number: orderNumber,
                  outbound_detail_id: order.outbound_detail_id,
                  total_order: order.total_order,
                  total_order_approved: order.total_order_approved,
                  fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                  product_id: productId,
                  bundle_id: null,
                  product_variant_id: product.product_variant_id,
                  product_name: product.product_name,
                  product_variant_name: product.product_variant_name || '-',
                  total_item: product.total_item,
                  is_bundling: product.is_bundling,
                  product_fulfillment: product.product_fulfillment,
                  packings: order.packings,
                })
              }
            })
          })

          this.items = result.map((item, index, array) => {
            const orderNumber = item.order_number
            const productId = item.product_id

            let orderRowspan = 0
            let productRowspan = 0

            if (index === 0 || orderNumber !== array[index - 1].order_number) {
              orderRowspan = orderCount[orderNumber]
            }
            if (
              index === 0
        || orderNumber !== array[index - 1].order_number
        || productId !== array[index - 1].product_id
            ) {
              productRowspan = productCount[orderNumber][productId]
            }

            return {
              ...item,
              orderRowspan,
              productRowspan,
            }
          })
        }).catch(err => {
          console.log(err)
        })
    },
    updateTotalOrderApproved(orderNumber, value) {
      this.items.forEach(item => {
        if (item.order_number === orderNumber) {
          // eslint-disable-next-line no-param-reassign
          item.total_order_approved = value
        }
      })
    },
    formatNumber(value) {
      return value ? value.replace(/[^\d]/g, '').slice(0, 4) : value
    },
    disabledConfirm() {
      // eslint-disable-next-line eqeqeq
      return this.items.some(item => item.total_order_approved === '' || item.total_order_approved > item.total_order) || this.isOrderDibatalkan
    },
    confirm() {
      newAxiosIns.put(`/kompack/api/v1/mitra/outbounds/${this.$route.params.id}/confirm`, this.payload)
        .then(res => {
          const { data } = res.data
          if (data.total_invalid_order === 0) {
            this.$swal({
              title: 'Sukses',
              text: 'Kamu akan mendapatkan komisi dari biaya fulfillment senilai order yang kamu checklist setelah kamu berhasil mengirimkan orderan ke ekspedisi.',
              icon: 'success',
              width: '45%',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
              confirmButtonText: 'Oke',
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                icon: 'border-0 w-25 my-5',
                confirmButton: 'btn btn-primary w-100',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.getDetailOrder()
              }
            })
          } else {
            this.$swal({
              title: 'Sukses',
              html: `Silakan proses hanya orderan yang telah kamu checklist sesuai resi yang kamu dapat ya. Orderan yang tidak sesuai resi akan dilaporkan ke partner.<div class="mt-6 text-[#AF6A13] bg-[#FFF2E2] px-[24px] py-[10px] rounded-lg text-start">Terdapat ${data.total_invalid_order} orderan yang tidak memiliki resi. </div>`,
              icon: 'warning',
              width: '45%',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              confirmButtonText: 'Selesai',
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                icon: 'border-0 w-25 my-5',
                confirmButton: 'btn btn-primary w-100',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.getDetailOrder()
              }
            })
          }
        }).catch(err => {
          console.log(err)
        })
    },
    async downloadLinkLable(url) {
      await newAxiosIns.put(`/kompack/api/v1/mitra/outbounds/${this.$route.params.id}/status-print`, { is_print: true }).then(() => {
        window.open(url, '_blank')
      })
    },
  },
}
</script>
